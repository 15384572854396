<template>
  <keep-alive :include="cacheList">
    <router-view/>
  </keep-alive>
</template>

<script>
export default {
  data(){
    return{
      cacheList: ['goodList']
    }
  }
}
</script>

<style>

</style>